/* Global Styles */
body {
  background-image: url('./pages/LoginPage1.jpg');
  background-size: cover;
  background-attachment: fixed;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #333;
}

.navbar-custom {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px 30px;
  transition: background-color 0.5s ease;
}

.navbar-custom a {
  color: #fff;
  margin-right: 20px;
  font-size: 18px;
  text-transform: uppercase;
}

.navbar-custom a:hover {
  color: #ffcc00;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.navbar-custom.scrolled {
  background-color: rgba(0, 0, 0, 0.9);
}

/* Container for Reports */
.report-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;
  gap: 20px;
}

.report-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 30px;
  width: 300px;
  text-align: center;
}

.report-card h3 {
  font-size: 20px;
  color: #222;
}

.report-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.report-card .icon {
  font-size: 40px;
  margin-bottom: 10px;
  color: #00b4d8;
}

.report-card .btn {
  margin-top: 20px;
  background-color: #00b4d8;
  color: white;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.report-card .btn:hover {
  background-color: #0077b6;
  transform: translateY(-3px);
}

/* Animation for entering cards */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.report-card {
  animation: fadeIn 0.8s ease-in-out;
}

/* Button for "Forgot Password" & "Sign In" */
.btn-custom {
  background-color: maroon;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.btn-custom:hover {
  background-color: maroon;
  transform: translateY(-5px);
}

/* Login Page */
.login-container {
  max-width: 400px;
  padding: 40px;
  background: transparent;
  /* background-color: rgba(255, 255, 255, 0.911); */
  /* opacity: 30%; */
  border-radius: 20px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
  margin: 100px auto;
}


.login-container h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 40px;
  color: white;
  font-style: bold;
}

.input-field {
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.input-field:focus {
  border-color: #0077b6;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6);
}

/* Footer */
.footer {
  background-color: #222;
  padding: 30px 0;
  color: white;
  text-align: center;
}

.footer a {
  color: #ffcc00;
}

.navbar-custom {
  background-color: #004d99;
  color: white;
}

.navbar-custom .nav-link {
  color: white;
  margin-right: 10px;
}

.navbar-custom .nav-link:hover {
  color: #f1c40f;
}

.report-container {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.report-card {
  background-color: #f9f9f9;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease;
}

.report-card:hover {
  transform: scale(1.05);
}

.footer {
  background-color: #004d99;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f4;
  text-align: left;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f1f1f1;
}
