.minimal-info {
    padding: 50px;
    background-color: #f9f9f9;
  }
  
  .minimal-info-title {
    font-size: 2rem;
    color: #333333;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .report-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .report-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .report-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  
  .report-card h3 {
    font-size: 1.4rem;
    color: #1e90ff;
  }
  
  .report-card p {
    font-size: 1.2rem;
    color: #333333;
  }
  